import React, { useEffect, useState, useCallback } from "react";
import { Redirect, useParams } from "react-router-dom";
import LaborShortageRisk from "./LaborShortageRisk";
import SafetyPerformance from "./SafetyPerformance";
import LaborProductivity from "./LaborProductivity";
import QualityPerformance from "./QualityPerformance";
import isEmpty from "lodash.isempty";
import Hashids from "hashids";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import { getLRIData, setLRIData } from "../../daos/lriData";
import { getProjectData } from "../../contexts/authHelpers";

const hashids = new Hashids("lri");

/* industry type -> industry role (owner, contractor, gc) 
/* probably "company_role" in the project details returned from overview clma call*/

const LRI = () => {
  const [projectId, setProjectId] = useState(""); // 51466049
  const [industryTypeId, setIndustryTypeId] = useState("");
  const [industryRole, setIndustryRole] = useState("");
  const [projectContractor, setProjectContractor] = useState("");
  const [projectOwner, setProjectOwner] = useState("");

  // overview data
  const [projectName, setProjectName] = useState("");
  // const [projectOwner, setProjectOwner] = useState("");
  // const [projectContractor, setProjectContractor] = useState("");
  const [totalIncludedCost, setTotalIncludedCost] = useState("");
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState("");

  const [safetyPerformanceData, setSafetyPerformanceData] = useState([]);
  const [laborShortageRiskData, setLaborShortageRiskData] = useState([]);
  const [laborProductivityData, setLaborProductivityData] = useState([]);
  const [qualityPerformanceData, setQualityPerformanceData] = useState([]);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [showVersionInput, setShowVersionInput] = useState(false);
  const [versionInput, setVersionInput] = useState("v_0");
  const [version, setVersion] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [getNewProject, setGetNewProject] = useState(false);
  const [gotoProjectOverview, setGotoProjectOverview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [lriScore, setLriScore] = useState("");
  const [safetyPerformanceScore, setSafetyPerformanceScore] = useState("");
  const [laborShortageRiskScore, setLaborShortageRiskScore] = useState("");
  const [laborProductivityScore, setLaborProductivityScore] = useState("");
  const [qualityPerformanceScore, setQualityPerformanceScore] = useState("");
  const [cwdaScore, setCwdaScore] = useState("");
  const [scaledCwdaScore, setScaledCwdaScore] = useState("");
  const [laborEfficiencyScore, setLaborEfficiencyScore] = useState("");
  const [actualShortageBucket, setActualShortageBucket] = useState("");
  const [selectedShortage, setSelectedShortage] = useState("");
  const [
    simulatedNoShortageCostReduction,
    setSimulatedNoShortageCostReduction,
  ] = useState("");
  const [
    simulatedNoShortageDurationReduction,
    setSimulatedNoShortageDurationReduction,
  ] = useState("");
  const [simulatedNoShortageLRI, setSimulatedNoShortageLRI] = useState("");
  const [
    simulatedNoShortageSafetyPerformanceScore,
    setSimulatedNoShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedNoShortageLaborShortageRiskScore,
    setSimulatedNoShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedNoShortageLaborProductivityScore,
    setSimulatedNoShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedNoShortageQualityPerformanceScore,
    setSimulatedNoShortageQualityPerformanceScore,
  ] = useState("");

  const [
    simulatedModerateShortageCostReduction,
    setSimulatedModerateShortageCostReduction,
  ] = useState("");
  const [
    simulatedModerateShortageDurationReduction,
    setSimulatedModerateShortageDurationReduction,
  ] = useState("");
  const [
    simulatedModerateShortageLRI,
    setSimulatedModerateShortageLRI,
  ] = useState("");
  const [
    simulatedModerateShortageSafetyPerformanceScore,
    setSimulatedModerateShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedModerateShortageLaborShortageRiskScore,
    setSimulatedModerateShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedModerateShortageLaborProductivityScore,
    setSimulatedModerateShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedModerateShortageQualityPerformanceScore,
    setSimulatedModerateShortageQualityPerformanceScore,
  ] = useState("");
  const [
    simulatedSevereShortageCostReduction,
    setSimulatedSevereShortageCostReduction,
  ] = useState("");
  const [
    simulatedSevereShortageDurationReduction,
    setSimulatedSevereShortageDurationReduction,
  ] = useState("");
  const [simulatedSevereShortageLRI, setSimulatedSevereShortageLRI] = useState(
    ""
  );
  const [
    simulatedSevereShortageSafetyPerformanceScore,
    setSimulatedSevereShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedSevereShortageLaborShortageRiskScore,
    setSimulatedSevereShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedSevereShortageLaborProductivityScore,
    setSimulatedSevereShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedSevereShortageQualityPerformanceScore,
    setSimulatedSevereShortageQualityPerformanceScore,
  ] = useState("");
  const [dataCompletenessCurrent, setDataCompletenessCurrent] = useState(0);
  const [dataCompletenessTotal, setDataCompletenessTotal] = useState(0);

  const handleSaveAsButton = () => {
    setShowVersionInput(true);
  };

  const handleValidateAndSubmitButton = () => {
    if (versionInput) {
      setShowVersionInput(false);
      setErrorMessage("");
      setVersion(versionInput);
      // localSetLRIData();
    } else {
      setErrorMessage("Please enter a version");
    }
  };

  const calculateDataCompleteness = useCallback(() => {
    // reset data completeness so we can re-calculate it
    let localDataCompletenessCurrent = 0;
    let localDataCompletenessTotal = 0;

    localDataCompletenessTotal += 3;
    if (safetyPerformanceData) {
      if (safetyPerformanceData[0]) {
        if (safetyPerformanceData[0].value) {
          localDataCompletenessCurrent++;
        }
      }
      if (safetyPerformanceData[1]) {
        if (safetyPerformanceData[1].value) {
          localDataCompletenessCurrent++;
        }
      }
      if (safetyPerformanceData[2]) {
        if (safetyPerformanceData[2].value) {
          localDataCompletenessCurrent++;
        }
      }
    }

    localDataCompletenessTotal += 6;
    if (laborShortageRiskData) {
      laborShortageRiskData.forEach((element, index) => {
        if (index !== 0) localDataCompletenessTotal += 5;
        if (index === 0 && element?.LaborShortageRiskCraft)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalIndustrySupply)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalPeakDemand)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskContractorWageRate)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalWageRate)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskAnnualNetAttrition)
          localDataCompletenessCurrent++;
      });
    }

    localDataCompletenessTotal += 3;
    if (laborProductivityData) {
      laborProductivityData.forEach((element, index) => {
        if (index !== 0) localDataCompletenessTotal += 2;
        if (index === 0 && element?.LaborProductivityCraft)
          localDataCompletenessCurrent++;
        if (element?.LaborProductivityContractorProductivity)
          localDataCompletenessCurrent++;
        if (element?.LaborProductivityIndustryAverageProductivity)
          localDataCompletenessCurrent++;
      });
    }

    localDataCompletenessTotal += 1;
    if (laborEfficiencyScore) localDataCompletenessCurrent++;

    localDataCompletenessTotal += 2;
    if (qualityPerformanceData) {
      if (qualityPerformanceData[0]?.QualityPerformanceContractorQPR)
        localDataCompletenessCurrent++;
      if (qualityPerformanceData[0]?.QualityPerformanceIndustryAverageQPR)
        localDataCompletenessCurrent++;
    }

    localDataCompletenessTotal += 1;
    if (cwdaScore) localDataCompletenessCurrent++;

    setDataCompletenessCurrent(localDataCompletenessCurrent);
    setDataCompletenessTotal(localDataCompletenessTotal);
  }, [
    cwdaScore,
    laborEfficiencyScore,
    laborProductivityData,
    laborShortageRiskData,
    qualityPerformanceData,
    safetyPerformanceData,
  ]);

  const getAllProjectData = useCallback(async () => {
    const projectRecord = await getProjectData(projectId);
    if (!isEmpty(projectRecord)) {
      setProjectName(projectRecord.data.name);
      setTotalIncludedCost(projectRecord.data.total_cost);
      const startDate = projectRecord.data.start_date;
      const startDateDate = new Date(
        startDate.slice(0, 4),
        startDate.slice(5, 7) - 1,
        startDate.slice(8, 10)
      );
      const startDateMonthName = startDateDate.toLocaleString("default", {
        month: "short",
      });
      const endDate = projectRecord.data.end_date;
      const endDateDate = new Date(
        endDate.slice(0, 4),
        endDate.slice(5, 7) - 1,
        endDate.slice(8, 10)
      );
      const endDateMonthName = endDateDate.toLocaleString("default", {
        month: "short",
      });
      setDuration(
        startDateDate.getFullYear() +
          startDateMonthName +
          startDateDate.getDate() +
          " - " +
          endDateDate.getFullYear() +
          endDateMonthName +
          endDateDate.getDate()
      );
      setLocation(
        projectRecord.data.city +
          ", " +
          projectRecord.data.state_abbr +
          " " +
          projectRecord.data.zip
      );
    }
    const record = await getLRIData({ projectId });
    setIsLoading(false);
    if (!isEmpty(record)) {
      setErrorMessage("");
      setProjectLoaded(true);
      setIndustryTypeId(record.industryTypeId);
      setIndustryRole(record.industryRole);
      setProjectOwner(record.projectOwner);
      setProjectContractor(record.projectContractor);
      setSafetyPerformanceData(record?.lriData?.safetyPerformanceData);
      setLaborShortageRiskData(record?.lriData?.laborShortageRiskData);
      setLaborProductivityData(record?.lriData?.laborProductivityData);
      setQualityPerformanceData(record?.lriData?.qualityPerformanceData);
      setLriScore(record.lriScore);
      setSafetyPerformanceScore(record.safetyPerformanceScore);
      setLaborShortageRiskScore(record.laborShortageRiskScore);
      setLaborProductivityScore(record.laborProductivityScore);
      setQualityPerformanceScore(record.qualityPerformanceScore);
      setCwdaScore(record.cwdaScore);
      setScaledCwdaScore(record.scaledCwdaScore);
      setLaborEfficiencyScore(record.laborEfficiencyScore);
      setActualShortageBucket(record.actualShortageBucket);
      setSelectedShortage(record.actualShortageBucket);
      setSimulatedNoShortageCostReduction(
        record.simulatedNoShortageCostReduction
      );
      setSimulatedNoShortageDurationReduction(
        record.simulatedNoShortageDurationReduction
      );
      setSimulatedNoShortageLRI(record.simulatedNoShortageLRI);
      setSimulatedNoShortageSafetyPerformanceScore(
        record.simulatedNoShortageSafetyPerformanceScore
      );
      setSimulatedNoShortageLaborShortageRiskScore(
        record.simulatedNoShortageLaborShortageRiskScore
      );
      setSimulatedNoShortageLaborProductivityScore(
        record.simulatedNoShortageLaborProductivityScore
      );
      setSimulatedNoShortageQualityPerformanceScore(
        record.simulatedNoShortageQualityPerformanceScore
      );
      setSimulatedModerateShortageCostReduction(
        record.simulatedModerateShortageCostReduction
      );
      setSimulatedModerateShortageDurationReduction(
        record.simulatedModerateShortageDurationReduction
      );
      setSimulatedModerateShortageLRI(record.simulatedModerateShortageLRI);
      setSimulatedModerateShortageSafetyPerformanceScore(
        record.simulatedModerateShortageSafetyPerformanceScore
      );
      setSimulatedModerateShortageLaborShortageRiskScore(
        record.simulatedModerateShortageLaborShortageRiskScore
      );
      setSimulatedModerateShortageLaborProductivityScore(
        record.simulatedModerateShortageLaborProductivityScore
      );
      setSimulatedModerateShortageQualityPerformanceScore(
        record.simulatedModerateShortageQualityPerformanceScore
      );
      setSimulatedSevereShortageCostReduction(
        record.simulatedSevereShortageCostReduction
      );
      setSimulatedSevereShortageDurationReduction(
        record.simulatedSevereShortageDurationReduction
      );
      setSimulatedSevereShortageLRI(record.simulatedSevereShortageLRI);
      setSimulatedSevereShortageSafetyPerformanceScore(
        record.simulatedSevereShortageSafetyPerformanceScore
      );
      setSimulatedSevereShortageLaborShortageRiskScore(
        record.simulatedSevereShortageLaborShortageRiskScore
      );
      setSimulatedSevereShortageLaborProductivityScore(
        record.simulatedSevereShortageLaborProductivityScore
      );
      setSimulatedSevereShortageQualityPerformanceScore(
        record.simulatedSevereShortageQualityPerformanceScore
      );
    }
    calculateDataCompleteness();
  }, [projectId]);

  useEffect(() => {
    setProjectId(id);
    setIsLoading(true);
    getAllProjectData();
  }, [id, getAllProjectData]);

  const localSetLRIData = useCallback(() => {
    setLRIData({
      projectId,
      industryTypeId,
      industryRole,
      projectOwner,
      projectContractor,
      lriData: {
        safetyPerformanceData,
        laborShortageRiskData,
        laborProductivityData,
        qualityPerformanceData,
      },
      lriScore,
      safetyPerformanceScore,
      laborShortageRiskScore,
      laborProductivityScore,
      qualityPerformanceScore,
      cwdaScore,
      scaledCwdaScore,
      laborEfficiencyScore,
      actualShortageBucket,
      simulatedNoShortageCostReduction,
      simulatedNoShortageDurationReduction,
      simulatedNoShortageLRI,
      simulatedNoShortageSafetyPerformanceScore,
      simulatedNoShortageLaborShortageRiskScore,
      simulatedNoShortageLaborProductivityScore,
      simulatedNoShortageQualityPerformanceScore,
      simulatedModerateShortageCostReduction,
      simulatedModerateShortageDurationReduction,
      simulatedModerateShortageLRI,
      simulatedModerateShortageSafetyPerformanceScore,
      simulatedModerateShortageLaborShortageRiskScore,
      simulatedModerateShortageLaborProductivityScore,
      simulatedModerateShortageQualityPerformanceScore,
      simulatedSevereShortageCostReduction,
      simulatedSevereShortageDurationReduction,
      simulatedSevereShortageLRI,
      simulatedSevereShortageSafetyPerformanceScore,
      simulatedSevereShortageLaborShortageRiskScore,
      simulatedSevereShortageLaborProductivityScore,
      simulatedSevereShortageQualityPerformanceScore,
      version,
    });
    calculateDataCompleteness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectId,
    industryTypeId,
    industryRole,
    projectOwner,
    projectContractor,
    safetyPerformanceData,
    laborShortageRiskData,
    laborProductivityData,
    qualityPerformanceData,
    lriScore,
    safetyPerformanceScore,
    laborShortageRiskScore,
    laborProductivityScore,
    qualityPerformanceScore,
    cwdaScore,
    scaledCwdaScore,
    laborEfficiencyScore,
    actualShortageBucket,
    simulatedNoShortageCostReduction,
    simulatedNoShortageDurationReduction,
    simulatedNoShortageLRI,
    simulatedNoShortageSafetyPerformanceScore,
    simulatedNoShortageLaborShortageRiskScore,
    simulatedNoShortageLaborProductivityScore,
    simulatedNoShortageQualityPerformanceScore,
    simulatedModerateShortageCostReduction,
    simulatedModerateShortageDurationReduction,
    simulatedModerateShortageLRI,
    simulatedModerateShortageSafetyPerformanceScore,
    simulatedModerateShortageLaborShortageRiskScore,
    simulatedModerateShortageLaborProductivityScore,
    simulatedModerateShortageQualityPerformanceScore,
    simulatedSevereShortageCostReduction,
    simulatedSevereShortageDurationReduction,
    simulatedSevereShortageLRI,
    simulatedSevereShortageSafetyPerformanceScore,
    simulatedSevereShortageLaborShortageRiskScore,
    simulatedSevereShortageLaborProductivityScore,
    simulatedSevereShortageQualityPerformanceScore,
    version,
  ]);

  useEffect(() => {
    if (safetyPerformanceData && safetyPerformanceData.length > 0) {
      localSetLRIData();
    }
  }, [safetyPerformanceData, localSetLRIData]);

  useEffect(() => {
    if (laborShortageRiskData && laborShortageRiskData.length > 0)
      localSetLRIData();
  }, [laborShortageRiskData, localSetLRIData]);

  useEffect(() => {
    if (laborProductivityData && laborProductivityData.length > 0)
      localSetLRIData();
  }, [laborProductivityData, localSetLRIData]);

  useEffect(() => {
    if (qualityPerformanceData && qualityPerformanceData.length > 0)
      localSetLRIData();
  }, [qualityPerformanceData, localSetLRIData]);

  const handleRequiredInputOnBlur = () => {
    localSetLRIData();
  };

  if (getNewProject) {
    return (
      <>
        <Redirect to="/project" />
      </>
    );
  }

  if (gotoProjectOverview) {
    return (
      <>
        <Redirect to={"/project/" + id} />
      </>
    );
  }

  return (
    <>
      {errorMessage ? <div>{errorMessage}</div> : null}
      {isLoading ? <div>Loading...</div> : null}
      {projectLoaded ? (
        <div>
          <div className="w-full flex pt-2 pb-4 border-b">
            <div className="pr-4">
              <button
                className="mx-auto rounded p-2 h-12 bg-blue-500 text-white"
                onClick={() => setGotoProjectOverview(true)}
              >
                Go to Project Overview
              </button>
            </div>
            <div>
              <button
                className="mx-auto rounded p-2 h-12 bg-blue-500 text-white"
                onClick={() => setGetNewProject(true)}
              >
                Load New Project
              </button>
            </div>
            <div className="my-auto p-2">
              Data completeness: {dataCompletenessCurrent}/
              {dataCompletenessTotal} (
              {dataCompletenessTotal &&
                Math.round(
                  (dataCompletenessCurrent / dataCompletenessTotal) * 100
                )}
              %)
            </div>
          </div>
          <div className="w-full md:max-w-4xl mx-auto">
            <div className="flex items-center h-16 mb-4">
              <div className="flex flex-row">
                <div className="flex my-auto rounded-full h-3 w-3 bg-gray-800"></div>
                <div className="ml-2">Project Details</div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <div className="max-w-xs">
                  <div className="max-w-xs text-center">
                    Industry Role
                    <Dropdown
                      isTextCentered={true}
                      selected={industryRole}
                      setSelected={setIndustryRole}
                      options={[
                        "Construction User/Owner",
                        "Contractor - GC and/or CM",
                        "Contractor - Prime",
                        "Contractor - Trade/Specialty",
                        "Labor Union",
                        "Supplier/Vendor",
                        "Supplier/Service Provider",
                        "Design/Build Organization",
                        "Engineering Organization",
                        "Architecture Firm",
                        "Consultant",
                        "Labor Provider",
                      ]}
                      onBlur={handleRequiredInputOnBlur}
                    />
                  </div>
                  <div className="max-w-xs text-center">
                    Project Owner
                    <Input
                      placeholder="Project Owner"
                      value={projectOwner}
                      onChange={(e) => setProjectOwner(e.target.value)}
                      onBlur={handleRequiredInputOnBlur}
                    />
                  </div>
                  <div className="max-w-xs text-center">
                    Project Contractor
                    <Input
                      placeholder="Project Contractor"
                      value={projectContractor}
                      onChange={(e) => setProjectContractor(e.target.value)}
                      onBlur={handleRequiredInputOnBlur}
                    />
                  </div>
                </div>
                <div className="flex ml-16">
                  <div className="max-w-xs">
                    <div className="max-w-xs">
                    Project Name: {projectName}
                    </div>
                    <div className="max-w-xs mt-2">
                      Total Included Cost: ${new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalIncludedCost)}
                    </div>
                    <div className="max-w-xs mt-2">
                      Duration: {duration}
                    </div>
                    <div className="max-w-xs mt-2">
                      Location: {location}
                    </div>
                  </div>
                </div>
              </div>

              {showVersionInput ? (
                <div className="flex flex-col">
                  <Input
                    value={versionInput}
                    onChange={(e) => setVersionInput(e.target.value)}
                    placeholder="Version e.g. 2020 Q1"
                  />
                  {errorMessage ? <div>{errorMessage}</div> : null}
                  <button
                    className="rounded p-2 h-12 bg-blue-500 text-white"
                    onClick={handleValidateAndSubmitButton}
                  >
                    Save Version
                  </button>
                </div>
              ) : (
                /* TODO add versions available somewhere in admin so you can pull up an old one  */
                <div className="flex">
                  <button
                    className="rounded p-2 h-12 bg-blue-500 text-white"
                    onClick={handleSaveAsButton}
                  >
                    Save as version...
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:max-w-4xl mx-auto mt-8">
            <div className="py-4">
              <SafetyPerformance
                projectId={projectId}
                safetyPerformanceData={safetyPerformanceData}
                setSafetyPerformanceData={setSafetyPerformanceData}
                safetyPerformanceScore={safetyPerformanceScore}
                admin={true}
              />
              <div className="max-w-xs pt-2 text-center">
                Safety Performance Score
                <Input
                  placeholder="Safety Performance Score"
                  value={safetyPerformanceScore}
                  onChange={(e) => setSafetyPerformanceScore(e.target.value)}
                  onBlur={handleRequiredInputOnBlur}
                />
              </div>
            </div>
            <div className="py-4">
              <LaborShortageRisk
                projectId={projectId}
                laborShortageRiskData={laborShortageRiskData}
                setLaborShortageRiskData={setLaborShortageRiskData}
                laborShortageRiskScore={laborShortageRiskScore}
                laborShortageBucket={actualShortageBucket}
                admin={true}
              />
              <div className="max-w-xs pt-2 text-center">
                Labor Shortage Risk Score
                <Input
                  placeholder="Labor Shortage Risk Score"
                  value={laborShortageRiskScore}
                  onChange={(e) => setLaborShortageRiskScore(e.target.value)}
                  onBlur={handleRequiredInputOnBlur}
                />
              </div>
            </div>
            <div className="py-4">
              <LaborProductivity
                projectId={projectId}
                laborProductivityData={laborProductivityData}
                setLaborProductivityData={setLaborProductivityData}
                laborProductivityScore={laborProductivityScore}
                laborEfficiencyScore={laborEfficiencyScore}
                admin={true}
              />
              <div className="max-w-xs pt-2 text-center">
                Labor Productivity Score
                <Input
                  placeholder="Labor Productivity Score"
                  value={laborProductivityScore}
                  onChange={(e) => setLaborProductivityScore(e.target.value)}
                  onBlur={handleRequiredInputOnBlur}
                />
              </div>
              <div className="max-w-xs pt-2 text-center">
                Labor Efficiency Index
                <Input
                  placeholder="Labor Efficiency Index"
                  value={laborEfficiencyScore}
                  onChange={(e) => setLaborEfficiencyScore(e.target.value)}
                  onBlur={handleRequiredInputOnBlur}
                />
              </div>
            </div>
            <div className="py-4">
              <QualityPerformance
                projectId={projectId}
                qualityPerformanceData={qualityPerformanceData}
                setQualityPerformanceData={setQualityPerformanceData}
                qualityPerformanceScore={qualityPerformanceScore}
                admin={true}
              />
              <div className="max-w-xs pt-2 text-center">
                Quality Performance Score:
                <Input
                  placeholder="Quality Performance Score"
                  value={qualityPerformanceScore}
                  onChange={(e) => setQualityPerformanceScore(e.target.value)}
                  onBlur={handleRequiredInputOnBlur}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col max-w-full mb-4">
                <div className="flex flex-row items-center h-16">
                  <div className="flex my-auto rounded-full h-3 w-3 bg-blue-500"></div>
                  <div className="ml-2 text-gray-800">
                    Contractors Workforce Development Assessment
                  </div>
                </div>
                <div className="flex flex-row divide-x divide-gray-500">
                  <div
                    className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded"
                    style={{
                      backgroundImage:
                        "linear-gradient(to top left, dimgray, black)",
                    }}
                  >
                    <div className="">CWDA Score:</div>
                    <div className="font-bold">{cwdaScore}</div>
                  </div>
                  <div
                    className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded"
                    style={{
                      backgroundImage:
                        "linear-gradient(to top left, dimgray, black)",
                    }}
                  >
                    <div className="">Scaled CWDA Score:</div>
                    <div className="font-bold">{scaledCwdaScore}</div>
                  </div>
                </div>
              </div>
              <div className="flex max-w-full text-center">
                <div className="w-full text-center">
                  CWDA Overall Score:
                  <Input
                    placeholder="Just an integer. No percent sign."
                    value={cwdaScore}
                    onChange={(e) => setCwdaScore(e.target.value)}
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
                <div className="w-full text-center">
                  Scaled CWDA Overall Score:
                  <Input
                    placeholder="Scaled CWDA Overall Score"
                    value={scaledCwdaScore}
                    onChange={(e) => setScaledCwdaScore(e.target.value)}
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col max-w-full mt-16">
              <div className="flex flex-row items-center h-16">
                <div className="flex my-auto rounded-full h-3 w-3 bg-black"></div>
                <div className="ml-2 text-gray-800">Shortage Simulator</div>
              </div>
            </div>
            <div className="max-w-xs mb-8 text-center">
              <div>
                <div className="max-w-xs text-center">
                  LRI Overall Default Score:
                  <Input
                    placeholder="LRI Overall Default Score"
                    value={lriScore}
                    onChange={(e) => setLriScore(e.target.value)}
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mb-2">Actual Shortage</div>
              <Dropdown
                selected={actualShortageBucket}
                setSelected={setActualShortageBucket}
                options={[
                  "Shortage (None to Minimal)",
                  "Shortage (Minimal to Moderate)",
                  "Shortage (Moderate to Severe)",
                ]}
                onBlur={handleRequiredInputOnBlur}
              />
            </div>
            <div
              className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded"
              style={{
                backgroundImage: "linear-gradient(to top left, dimgray, black)",
              }}
            >
              <div className="">Simulated Shortage Data</div>
            </div>
            <div className="mt-4 mb-2 text-center font-bold">
              None to Minimal
            </div>
            <div className="flex flex-row w-full justify-between">
              <div className="mr-1 text-xs text-center">
                Cost Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedNoShortageCostReduction}
                    onChange={(e) =>
                      setSimulatedNoShortageCostReduction(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Duration Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedNoShortageDurationReduction}
                    onChange={(e) =>
                      setSimulatedNoShortageDurationReduction(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                LRI
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedNoShortageLRI}
                    onChange={(e) => setSimulatedNoShortageLRI(e.target.value)}
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Safety Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.2"
                    value={simulatedNoShortageSafetyPerformanceScore}
                    onChange={(e) =>
                      setSimulatedNoShortageSafetyPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Shortage Risk
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.2"
                    value={simulatedNoShortageLaborShortageRiskScore}
                    onChange={(e) =>
                      setSimulatedNoShortageLaborShortageRiskScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Productivity
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.2"
                    value={simulatedNoShortageLaborProductivityScore}
                    onChange={(e) =>
                      setSimulatedNoShortageLaborProductivityScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="text-xs text-center">
                Quality Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.2"
                    value={simulatedNoShortageQualityPerformanceScore}
                    onChange={(e) =>
                      setSimulatedNoShortageQualityPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2 text-center font-bold">
              Minimal to Moderate
            </div>
            <div className="flex flex-row w-full justify-between">
              <div className="mr-1 text-xs text-center">
                Cost Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedModerateShortageCostReduction}
                    onChange={(e) =>
                      setSimulatedModerateShortageCostReduction(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Duration Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedModerateShortageDurationReduction}
                    onChange={(e) =>
                      setSimulatedModerateShortageDurationReduction(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                LRI
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedModerateShortageLRI}
                    onChange={(e) =>
                      setSimulatedModerateShortageLRI(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Safety Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedModerateShortageSafetyPerformanceScore}
                    onChange={(e) =>
                      setSimulatedModerateShortageSafetyPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Shortage Risk
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedModerateShortageLaborShortageRiskScore}
                    onChange={(e) =>
                      setSimulatedModerateShortageLaborShortageRiskScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Productivity
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedModerateShortageLaborProductivityScore}
                    onChange={(e) =>
                      setSimulatedModerateShortageLaborProductivityScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="text-xs text-center">
                Quality Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedModerateShortageQualityPerformanceScore}
                    onChange={(e) =>
                      setSimulatedModerateShortageQualityPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2 text-center font-bold">
              Moderate to Severe
            </div>
            <div className="flex flex-row w-full justify-between">
              <div className="mr-1 text-xs text-center">
                Cost Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedSevereShortageCostReduction}
                    onChange={(e) =>
                      setSimulatedSevereShortageCostReduction(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Duration Reduction %
                <div className="">
                  <Input
                    placeholder="A number e.g. 50"
                    value={simulatedSevereShortageDurationReduction}
                    onChange={(e) =>
                      setSimulatedSevereShortageDurationReduction(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                LRI
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedSevereShortageLRI}
                    onChange={(e) =>
                      setSimulatedSevereShortageLRI(e.target.value)
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Safety Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedSevereShortageSafetyPerformanceScore}
                    onChange={(e) =>
                      setSimulatedSevereShortageSafetyPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Shortage Risk
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedSevereShortageLaborShortageRiskScore}
                    onChange={(e) =>
                      setSimulatedSevereShortageLaborShortageRiskScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="mr-1 text-xs text-center">
                Labor Productivity
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedSevereShortageLaborProductivityScore}
                    onChange={(e) =>
                      setSimulatedSevereShortageLaborProductivityScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
              <div className="text-xs text-center">
                Quality Performance
                <div className="">
                  <Input
                    placeholder="A number e.g. 1.24"
                    value={simulatedSevereShortageQualityPerformanceScore}
                    onChange={(e) =>
                      setSimulatedSevereShortageQualityPerformanceScore(
                        e.target.value
                      )
                    }
                    onBlur={handleRequiredInputOnBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LRI;

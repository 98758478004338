import React from 'react'
import NumberFormat from 'react-number-format';
import truncate from 'lodash.truncate';

const HeroStat = ({ data, label, isCurrency, isWide }) => {

  //const longStr = 'lkahjsdf  asdhf kljh adfs alskdjfhlasd jfklajshdflaj sdffl adslfkjhlasd kfjljashdf haklsjdhflkajsdhf  asdkfjhal sdf'

  return (
    <section className={isWide ? 'text-center col-span-2' : 'text-center'}>
      {isCurrency
        ? <NumberFormat value={data} displayType={'text'} thousandSeparator={true} prefix={'$'} className="text-md text-white"/>
        : <div className="text-md text-white">{ data ? truncate(data, {length: 50}) : '\u00A0' }</div>
      }
      <div className="text-sm text-gray-500">{ label ? label : '\u00A0'}</div>
    </section>
  )
}

export default HeroStat
import AWS from "aws-sdk";

const config = require("../config/dev.json");

const lriDetails = "lri-details";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: config.aws.a,
  secretAccessKey: config.aws.s,
});

const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2012-08-10" });

export const getLRIData = async ({ projectId, version }) => {
  if (!projectId) {
    return {};
  }

  const timestamp = Date.now();

  const dynamodbParams = {
    TableName: lriDetails,
    Key: {
      projectId,
      version: version ? version : "v_0",
    },
  };

  let data = await docClient.get(dynamodbParams).promise();
  if (data?.Item) {
    // item exists, don't do anything
  } else {
    // item doesn't exist, create a new empty item
    data = await docClient
      .put({
        TableName: lriDetails,
        Item: {
          actualShortageBucket: "",
          createdAt: {
            S: timestamp,
          },
          cwdaScore: "",
          industryRole: "",
          industryTypeId: "",
          laborEfficiencyScore: "",
          laborProductivityScore: "",
          laborShortageRiskScore: "",
          lriData: {
            laborProductivityData: [],
            laborShortageRiskData: [],
            qualityPerformanceData: [],
            safetyPerformanceData: [],
          },
          lriScore: "",
          projectContractor: "",
          projectId,
          projectOwner: "",
          qualityPerformanceScore: "",
          safetyPerformanceScore: "",
          scaledCwdaScore: "",
          simulatedModerateShortageCostReduction: "",
          simulatedModerateShortageDurationReduction: "",
          simulatedModerateShortageLaborProductivityScore: "",
          simulatedModerateShortageLaborShortageRiskScore: "",
          simulatedModerateShortageLRI: "",
          simulatedModerateShortageQualityPerformanceScore: "",
          simulatedModerateShortageSafetyPerformanceScore: "",
          simulatedNoShortageCostReduction: "",
          simulatedNoShortageDurationReduction: "",
          simulatedNoShortageLaborProductivityScore: "",
          simulatedNoShortageLaborShortageRiskScore: "",
          simulatedNoShortageLRI: "",
          simulatedNoShortageQualityPerformanceScore: "",
          simulatedNoShortageSafetyPerformanceScore: "",
          simulatedSevereShortageCostReduction: "",
          simulatedSevereShortageDurationReduction: "",
          simulatedSevereShortageLaborProductivityScore: "",
          simulatedSevereShortageLaborShortageRiskScore: "",
          simulatedSevereShortageLRI: "",
          simulatedSevereShortageQualityPerformanceScore: "",
          simulatedSevereShortageSafetyPerformanceScore: "",
          version: "v_0",
        },
      })
      .promise();

    // get the newly created item
    data = await docClient.get(dynamodbParams).promise();
  }
  const { Item: record = {} } = data;
  return record;
};

export const getLRIAverages = async ({ industryRole }) => {
  try {
    var params = {
      TableName: lriDetails,
    };
    const data = await docClient.scan(params).promise();
    const { Items: records = {} } = data;
    const lriIndustryScores = [];
    const lriPeerScores = [];
    const industryBucket = {
      "Construction User/Owner": 1,
      "Contractor - GC and/or CM": 2,
      "Contractor - Prime": 2,
      "Contractor - Trade/Specialty": 3,
      "Labor Union": 4,
      "Supplier/Vendor": 5,
      "Supplier/Service Provider": 5,
      "Design/Build Organization": 6,
      "Engineering Organization": 6,
      "Architecture Firm": 6,
      "Consultant": 7,
      "Labor Provider": 4,
    }

    records.forEach((record) => {
      if (record.lriScore) {
        lriIndustryScores.push(record.lriScore);

        if (industryBucket[record.industryRole] === industryBucket[industryRole]) {
          lriPeerScores.push(record.lriScore);
        }
      }
    });

    const lriIndustryScoresSum = lriIndustryScores.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const lriIndustryScoresAvg =
      lriIndustryScoresSum / lriIndustryScores.length || 0;
    const lriPeerScoresSum = lriPeerScores.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    const lriPeerScoresAvg = lriPeerScoresSum / lriPeerScores.length || 0;

    return { lriIndustryScoresAvg, lriPeerScoresAvg };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const setLRIData = async ({
  projectId,
  industryTypeId,
  industryRole,
  projectOwner,
  projectContractor,
  lriData,
  version,
  lriScore,
  safetyPerformanceScore,
  laborShortageRiskScore,
  laborProductivityScore,
  qualityPerformanceScore,
  cwdaScore,
  scaledCwdaScore,
  laborEfficiencyScore,
  actualShortageBucket,
  simulatedNoShortageCostReduction,
  simulatedNoShortageDurationReduction,
  simulatedNoShortageLRI,
  simulatedNoShortageSafetyPerformanceScore,
  simulatedNoShortageLaborShortageRiskScore,
  simulatedNoShortageLaborProductivityScore,
  simulatedNoShortageQualityPerformanceScore,
  simulatedModerateShortageCostReduction,
  simulatedModerateShortageDurationReduction,
  simulatedModerateShortageLRI,
  simulatedModerateShortageSafetyPerformanceScore,
  simulatedModerateShortageLaborShortageRiskScore,
  simulatedModerateShortageLaborProductivityScore,
  simulatedModerateShortageQualityPerformanceScore,
  simulatedSevereShortageCostReduction,
  simulatedSevereShortageDurationReduction,
  simulatedSevereShortageLRI,
  simulatedSevereShortageSafetyPerformanceScore,
  simulatedSevereShortageLaborShortageRiskScore,
  simulatedSevereShortageLaborProductivityScore,
  simulatedSevereShortageQualityPerformanceScore,
}) => {
  if (!projectId) return {};
  projectId = projectId.toString();

  await docClient
    .put({
      TableName: lriDetails,
      Item: {
        projectId,
        version: version ? version : "v_0",
        industryTypeId,
        industryRole,
        projectOwner,
        projectContractor,
        lriData: lriData,
        lriScore,
        safetyPerformanceScore,
        laborShortageRiskScore,
        laborProductivityScore,
        qualityPerformanceScore,
        cwdaScore,
        scaledCwdaScore,
        laborEfficiencyScore,
        actualShortageBucket,
        simulatedNoShortageCostReduction,
        simulatedNoShortageDurationReduction,
        simulatedNoShortageLRI,
        simulatedNoShortageSafetyPerformanceScore,
        simulatedNoShortageLaborShortageRiskScore,
        simulatedNoShortageLaborProductivityScore,
        simulatedNoShortageQualityPerformanceScore,
        simulatedModerateShortageCostReduction,
        simulatedModerateShortageDurationReduction,
        simulatedModerateShortageLRI,
        simulatedModerateShortageSafetyPerformanceScore,
        simulatedModerateShortageLaborShortageRiskScore,
        simulatedModerateShortageLaborProductivityScore,
        simulatedModerateShortageQualityPerformanceScore,
        simulatedSevereShortageCostReduction,
        simulatedSevereShortageDurationReduction,
        simulatedSevereShortageLRI,
        simulatedSevereShortageSafetyPerformanceScore,
        simulatedSevereShortageLaborShortageRiskScore,
        simulatedSevereShortageLaborProductivityScore,
        simulatedSevereShortageQualityPerformanceScore,
      },
    })
    .promise();
};

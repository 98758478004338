import React, { useState, useEffect } from "react";
import Input from "../../components/Input";
import { Redirect } from "react-router-dom";

const GetNewProject = () => {
  const [getNewProject, setGetNewProject] = useState(false);
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    if (projectId.length === 8) {
      setGetNewProject(true);
    }
  }, [projectId, setGetNewProject]);

  if (getNewProject) {
    return <Redirect to={"/project/" + projectId + "/details"} />;
  }

  return (
    <div className="pl-4 max-w-xs">
      <Input
        placeholder="8 Digit Project ID e.g. 51466049"
        value={projectId}
        onChange={(e) => setProjectId(e.target.value)}
      />
    </div>
  );
};

export default GetNewProject;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import ComparisonBar from "./ComparisonBar";

const IndustryComparison = ({
  lriScore,
  industryLriAvg,
  peerLriAvg,
  you,
  dataCompletenessCurrent,
  dataCompletenessTotal,
}) => {
  const [trendUp, setTrendUp] = useState(true);
  const [trendValue, setTrendValue] = useState(0);
  const [scoreComparedToIndustry, setScoreComparedToIndustry] = useState(0);
  const [scoreComparedToPeers, setScoreComparedToPeers] = useState(0);

  useEffect(() => {
    setTrendValue(0.0);
    setTrendUp(true);
    setScoreComparedToIndustry(Math.floor((lriScore * 100) / industryLriAvg));
    setScoreComparedToPeers(Math.floor((lriScore * 100) / peerLriAvg));
  }, [lriScore, industryLriAvg, peerLriAvg]);

  return (
    <>
      <section className="flex flex-col mx-12 shadow text-white">
        <div className="flex flex-row bg-blue-500 h-24 rounded-t">
          <div className="flex flex-col w-1/5 ml-4 my-2">
            {/* LRI score overall and trend */}
            <div className="flex flex-row">
              <div className="flex flex-row w-1/2 text-left">LRI</div>
              <div className="flex flex-row items-start">
                {trendValue ? (
                  <div>
                    {trendUp ? (
                      <FontAwesomeIcon
                        className="text-green-500"
                        icon={faCaretUp}
                        size="2x"
                        transform="up-3"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-red-500"
                        icon={faCaretDown}
                        size="2x"
                        transform="up-4"
                      />
                    )}
                    <p>{trendValue}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex -mt-4">
              <p className="text-5xl font-bold text-left">
                {parseFloat(lriScore).toFixed(2)}
              </p>
            </div>
          </div>

          {/* LRI score compared to industry and peers */}
          <div className="flex flex-grow flex-row w-3/5 bg-blue-600 m-2 p-2">
            <div className="w-3/5 pr-8 my-auto leading-snug text-xl">
              Your score is {scoreComparedToIndustry}%{" "}
              {lriScore >= industryLriAvg ? "of" : "of"} Industry overall
              and {scoreComparedToPeers}%{" "}
              {lriScore >= peerLriAvg ? "of" : "of"} your Peers
            </div>
            <div className="w-1/5 text-xs text-gray-400 text-center mr-2 whitespace-no-wrap truncate">
              Industry Average
              <div className="text-4xl text-white">
                {parseFloat(industryLriAvg).toFixed(2)}
              </div>
            </div>
            <div className="text-xs text-gray-400 text-center whitespace-no-wrap truncate">
              Peer Average
              <div className="text-4xl text-white">
                {parseFloat(peerLriAvg).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col rounded-b"
          style={{
            height: "7.75rem",
            backgroundImage: "linear-gradient(to top left, dimgray, black)",
          }}
        >
          <ComparisonBar
            lriScore={lriScore}
            industryAverageScore={industryLriAvg}
            peerAverageScore={peerLriAvg}
            you={you}
          />
        </div>
      </section>
      <div className="mx-12 mb-12">
        {dataCompletenessTotal && (
          <div className="text-black text-sm">
            Data Completeness ={" "}
            {Math.round(
              (dataCompletenessCurrent / dataCompletenessTotal) * 100
            )}
            %
          </div>
        )}
      </div>
    </>
  );
};

export default IndustryComparison;

import React from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from "react-table";
import matchSorter from "match-sorter";
import { getLRIData, setLRIData } from "../../../daos/lriData";
import Button from "../../../components/Button";
import ErrorBoundary from "../../../components/ErrorBoundary";

const Styles = styled.div`
  table {
    width: 100%;

    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

// Create an editable cell renderer
const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue || "");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  return (
    <input
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      style={{ textAlign: "center" }}
    />
  );
};

// Create an editable cell renderer
const SelectableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue || "");

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }
  return (
    <select onChange={onChange} onBlur={onBlur} value={value || ""}>
      <option>Select</option>
      {[
        "Boilermaker",
        "Boilermaker Welder",
        "Boilermaker Welder (Mig)",
        "Boilermaker Welder (Tig)",
        "Bricklayer / Blockmason",
        "Carpenter (All Unspecified)",
        "Carpenter (Concrete Form / Rough)",
        "Carpenter (Finishing)",
        "Carpenter (Floor Covering Installer)",
        "Carpenter (Interior Systems)",
        "Carpenter (Lather)",
        "Carpenter (Pile Driver / Operator)",
        "Carpenter (Scaffold Builder)",
        "Carpenter (Welder)",
        "Concrete Finisher / Cement Mason",
        "Craft Helper",
        "Electrician",
        "Elevator Installer and Repairer",
        "Firewatch",
        "Floorhand",
        "Glazier",
        "HVAC/Refrigeration Mechanic",
        "Instrumentation Technician",
        "Insulator",
        "Ironworker (Reinforcing)",
        "Ironworker / Welder (Structural)",
        "Laborer",
        "Lineman",
        "Machinist",
        "Millwright",
        "Millwright (Welder)",
        "Motorman",
        "Operator (Driller and Blaster)",
        "Operator (Heavy Crane)",
        "Operator (Heavy Equipment Mechanic)",
        "Operator (Heavy Equipment)",
        "Operator (Material Handler)",
        "Operator (Rotary Driller Oil and Gas)",
        "Operator (Truck Driver)",
        "Other",
        "Painter",
        "Pipefitter",
        "Pipefitter (Sprinkler Systems)",
        "Pipefitter / Combo Welder",
        "Pipelayer (Under Ground)",
        "Plasterer / Stucco Mason",
        "Plumber",
        "Rigger / Signalperson",
        "Roofer",
        "Roughneck",
        "Sheet Metal Worker",
        "Tile / Marble Setter",
        "Tool Drag",
        "Welder (Speciality)",
        "Welder (Underwater)",
      ].map((o) => (
        <option value={o} key={o}>
          {o}
        </option>
      ))}
    </select>
  );
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({
  columns,
  data,
  updateMyData,
  disablePageResetOnDataChange,
  admin,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      Cell: EditableCell,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data
      disablePageResetOnDataChange,
      admin,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.isExpanded ? "👇" : "👉"}
                            </span>{" "}
                            {cell.render("Cell", { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell", {
                            editable: admin ? true : false,
                          })
                        )}
                      </td>
                    );
                  })}
                </tr>
              )
          )}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              groupBy,
              expanded,
              filters,
              selectedRowPaths,
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

const App = ({
  projectId,
  laborProductivityData,
  setLaborProductivityData,
  laborProductivityScore,
  laborEfficiencyScore,
  admin,
}) => {

  const [data, setData] = React.useState([]);
  const [originalData] = React.useState(data);

  const columns = React.useMemo(
    () => [
      {
        Header: "Craft",
        accessor: "LaborProductivityCraft",
        Cell: SelectableCell,
      },
      {
        Header: "Contractor Productivity",
        accessor: "LaborProductivityContractorProductivity",
        Cell: admin
          ? EditableCell
          : (props) =>
              new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(props.value),
      },
      {
        Header: "Industry Average Productivity",
        accessor: "LaborProductivityIndustryAverageProductivity",
        Cell: admin
          ? EditableCell
          : (props) =>
              new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(props.value),
      },
    ],
    []
  );

  const adminColumns = React.useMemo(
    () => [
      {
        Header: "Craft",
        accessor: "LaborProductivityCraft",
        Cell: SelectableCell,
      },
      {
        Header: "Contractor Productivity",
        accessor: "LaborProductivityContractorProductivity",
        Cell: admin
          ? EditableCell
          : (props) =>
              new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(props.value),
      },
      {
        Header: "Industry Average Productivity",
        accessor: "LaborProductivityIndustryAverageProductivity",
        Cell: admin
          ? EditableCell
          : (props) =>
              new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(props.value),
      },
      {
        Header: "Admin",
        id: "admin",
        accessor: (str) => "admin",
        //width: 556 / 6,
        Cell: (tableProps) => (
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              // ES6 Syntax use the rvalue if your data is an array.
              const dataCopy = Array.from(data);
              // It should not matter what you name tableProps. It made the most sense to me.
              const spliced = dataCopy.splice(tableProps.row.index, 1);

              setData(dataCopy);
            }}
          >
            Delete
          </span>
        ),
      },
    ],
    [data]
  );


  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipPageResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnID and new value to update the
  // original data
  const updateMyData = (rowIndex, columnID, value) => {

    // We also turn on the flag to not reset the page
    skipPageResetRef.current = true;
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnID]: value,
          };
        }
        return row;
      })
    );
  };

  const setInitialData = async () => {

    const record = await getLRIData({ projectId });
    setData(record?.lriData?.laborProductivityData);
  };

  React.useEffect(() => {

    setInitialData();
  }, []);

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipPageResetRef.current = false;
    setLaborProductivityData(data);
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    // Don't reset the page when we do this
    skipPageResetRef.current = true;
    setData(originalData);
  };

  // Add a new row of empty data
  const addEmptyRow = () => {
    skipPageResetRef.current = true;
    const newData = Array.from(data);
    newData.push({});
    setData(newData);
  };

  return (
    <Styles>
      <div className="flex flex-col max-w-full">
        <div className="flex flex-row items-center h-16">
          <div className="flex my-auto rounded-full h-3 w-3 bg-yellow-500"></div>
          <div className="ml-2 text-gray-800">
            Labor Productivity (CLMA&#174; - HLCP)
          </div>
        </div>
        <div
          className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded-t"
          style={{
            backgroundImage: "linear-gradient(to top left, dimgray, black)",
          }}
        >
          <div className="flex flex-col">
            <div className="ml-2">Project Labor Productivity Score:</div>
            <div className="ml-2 text-sm text-gray-500">
              Labor Efficiency Index (LEI):
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-bold">
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(laborProductivityScore)}
            </div>
            <div className="text-sm">
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(laborEfficiencyScore)}
            </div>
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <Table
          columns={admin ? adminColumns : columns}
          data={data}
          updateMyData={updateMyData}
          disablePageResetOnDataChange={skipPageResetRef.current}
          admin={admin}
        />
      </ErrorBoundary>
      {admin ? <Button onClick={addEmptyRow}>Add Empty Row</Button> : null}
    </Styles>
  );
};

export default App;

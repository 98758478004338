import React from "react";

import {
  HorizontalGridLines,
  XAxis,
  FlexibleXYPlot,
  YAxis,
  VerticalBarSeries,
  MarkSeries,
  GradientDefs,
} from "react-vis";
import "react-vis/dist/style.css";
{
}

const Chart = ({
  safetyPerformanceScore,
  laborShortageRiskScore,
  laborProductivityScore,
  qualityPerformanceScore,
  cwdaScore,
  simulatorToggle,
  selectedShortage,
  simulatedNoShortageSafetyPerformanceScore,
  simulatedNoShortageLaborShortageRiskScore,
  simulatedNoShortageLaborProductivityScore,
  simulatedNoShortageQualityPerformanceScore,
  simulatedModerateShortageSafetyPerformanceScore,
  simulatedModerateShortageLaborShortageRiskScore,
  simulatedModerateShortageLaborProductivityScore,
  simulatedModerateShortageQualityPerformanceScore,
  simulatedSevereShortageSafetyPerformanceScore,
  simulatedSevereShortageLaborShortageRiskScore,
  simulatedSevereShortageLaborProductivityScore,
  simulatedSevereShortageQualityPerformanceScore,
}) => {
  const data = [
    {
      x: "Safety Performance",
      y: safetyPerformanceScore,
      color: "#4299e1",
    },
    {
      x: "Labor Shortage Risk",
      y: laborShortageRiskScore,
      color: "#38b2ac",
    },
    {
      x: "Labor Productivity",
      y: laborProductivityScore,
      color: "#ecc94b",
    },
    {
      x: "Quality Performance",
      y: qualityPerformanceScore,
      color: "#9f7aea",
    },
    { x: "CWDA Score", y: cwdaScore, color: "#ed8936" },
  ];

  const simulatedData = [
    {
      x: "Safety Performance",
      y:
        selectedShortage === "Shortage (None to Minimal)"
          ? simulatedNoShortageSafetyPerformanceScore
          : selectedShortage === "Shortage (Moderate to Severe)"
          ? simulatedSevereShortageSafetyPerformanceScore
          : simulatedModerateShortageSafetyPerformanceScore,
    },
    {
      x: "Labor Shortage Risk",
      y:
        selectedShortage === "Shortage (None to Minimal)"
          ? simulatedNoShortageLaborShortageRiskScore
          : selectedShortage === "Shortage (Moderate to Severe)"
          ? simulatedSevereShortageLaborShortageRiskScore
          : simulatedModerateShortageLaborShortageRiskScore,
    },
    {
      x: "Labor Productivity",
      y:
        selectedShortage === "Shortage (None to Minimal)"
          ? simulatedNoShortageLaborProductivityScore
          : selectedShortage === "Shortage (Moderate to Severe)"
          ? simulatedSevereShortageLaborProductivityScore
          : simulatedModerateShortageLaborProductivityScore,
    },
    {
      x: "Quality Performance",
      y:
        selectedShortage === "Shortage (None to Minimal)"
          ? simulatedNoShortageQualityPerformanceScore
          : selectedShortage === "Shortage (Moderate to Severe)"
          ? simulatedSevereShortageQualityPerformanceScore
          : simulatedModerateShortageQualityPerformanceScore,
    },
    { x: "CWDA Score", y: cwdaScore },
  ];

  const maxDomain = Math.max(
    safetyPerformanceScore,
    laborShortageRiskScore,
    laborProductivityScore,
    qualityPerformanceScore,
    cwdaScore,
    simulatedNoShortageSafetyPerformanceScore,
    simulatedNoShortageLaborShortageRiskScore,
    simulatedNoShortageLaborProductivityScore,
    simulatedNoShortageQualityPerformanceScore,
    simulatedModerateShortageSafetyPerformanceScore,
    simulatedModerateShortageLaborShortageRiskScore,
    simulatedModerateShortageLaborProductivityScore,
    simulatedModerateShortageQualityPerformanceScore,
    simulatedSevereShortageSafetyPerformanceScore,
    simulatedSevereShortageLaborShortageRiskScore,
    simulatedSevereShortageLaborProductivityScore,
    simulatedSevereShortageQualityPerformanceScore
  );

  const minDomain = Math.min(safetyPerformanceScore,
    laborShortageRiskScore,
    laborProductivityScore,
    qualityPerformanceScore,
    cwdaScore,
    simulatedNoShortageSafetyPerformanceScore,
    simulatedNoShortageLaborShortageRiskScore,
    simulatedNoShortageLaborProductivityScore,
    simulatedNoShortageQualityPerformanceScore,
    simulatedModerateShortageSafetyPerformanceScore,
    simulatedModerateShortageLaborShortageRiskScore,
    simulatedModerateShortageLaborProductivityScore,
    simulatedModerateShortageQualityPerformanceScore,
    simulatedSevereShortageSafetyPerformanceScore,
    simulatedSevereShortageLaborShortageRiskScore,
    simulatedSevereShortageLaborProductivityScore,
    simulatedSevereShortageQualityPerformanceScore)

  const gradient = (
    <GradientDefs>
      <linearGradient
        id="myGradient"
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="0"
        x2="200"
        y2="200"
      >
        <stop offset="10%" stopColor="#c6e48b" />
        <stop offset="33%" stopColor="#7bc96f" />
        <stop offset="66%" stopColor="#239a3b" />
        <stop offset="90%" stopColor="#196127" />
      </linearGradient>
    </GradientDefs>
  );

  return (
    <section className="flex h-64">
      <FlexibleXYPlot
        xType="ordinal"
        margin={{ left: 40, right: 10, top: 12, bottom: 40 }}
        // yDomain={[0,2]}
        // yPadding={20}
        yDomain={[minDomain < 0 ? minDomain*1.25 : 0, maxDomain*1.25]}
        // yPadding
      >
        <XAxis
          style={{
            line: { stroke: "black", strokeWidth: 0.75 },
            tick: { stroke: "black", strokeWidth: 0.75 },
            text: { stroke: "black", strokeWidth: 0.25, fontSize: 14 },
          }}
        />
        <YAxis
          style={{
            line: { stroke: "black", strokeWidth: 0.75 },
            tick: { stroke: "black", strokeWidth: 0.75 },
            text: { stroke: "black", strokeWidth: 0.25, fontSize: 14 },
          }}
          //tickValues={[0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0]}
        />
        {gradient}
        <HorizontalGridLines
          tickValues={[1]}
          style={{ stroke: "black", strokeWidth: 0.75 }}
        />

        <VerticalBarSeries data={data} colorType="literal" barWidth={0.25} />
        {simulatorToggle ? (
          <MarkSeries
            className="pt-8"
            data={simulatedData}
            colorType="literal"
            color="dimgray"
            stroke="#555555"
            strokeWidth={1}
            size="10"
          />
        ) : null}
      </FlexibleXYPlot>
    </section>
  );
};

export default Chart;

import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

const Dropdown = ({ options, selected, setSelected, isTextCentered }) => {
  const [isOpen, setIsOpen] = useState(false);

  const buttonHandler = () => {
    setIsOpen(!isOpen);
  };

  const optionHandler = (event) => {
    setSelected(event.target.getAttribute("data-value"));
    setIsOpen(false);
  };

  const blurHandler = (event) => {
    // currentTarget refers to this component.
    // relatedTarget refers to the element where the user clicked (or focused) which triggered this event.
    // So in effect, this condition checks if the user clicked outside the component.
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={isTextCentered ? "relative inline-block text-center w-full" : "relative inline-block text-left w-full"}
      onBlur={blurHandler}
    >
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-left text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            onClick={buttonHandler}
          >
            {selected}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>

      {/* <!--
    Dropdown panel, show/hide based on dropdown state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  --> */}
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames={{
          enter: "transform opacity-0 scale-95",
          enterActive:
            "transition ease-out duration-100 transform opacity-100 scale-100",
          exit: "transform opacity-100 scale-100",
          exitActive:
            "transition ease-in duration-75 transform opacity-100 scale-100",
        }}
        unmountOnExit
      >
        <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg z-10">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              {options.map((option) => {
                return (
                  <div
                    className={isTextCentered ? "text-center block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" : "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"}
                    onClick={optionHandler}
                    data-value={option}
                    key={option}
                  >
                    {option}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;

import axios from 'axios';
import AWS from 'aws-sdk';
import get from 'lodash.get';
import { getUserPreferences } from '../daos/userPreferences';

const config = require('../config/dev.json');
const userPrefsTable = 'cwda-user-preferences';

AWS.config.update({
	region: 'us-east-1',
	accessKeyId: config.aws.a,
	secretAccessKey: config.aws.s,
});

const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-08-10' });
const orgPreferencesTable = 'cwda-organization-preferences';

export const userSignIn = async (email, password, setUserData, setOrgData) => {
	const postValue = {
		user2: {
			email,
			password,
		},
		track_user: false,
	};

	const { data } = await axios
		.post('https://app.myclma.com/user2/sign_in', postValue, {
			headers: { 'content-type': 'application/json' },
		})
		.catch(function(error) {
			console.log(error);
		});

	const authToken = get(data, 'account.tagged_accounts_tokens[0]', '');

	const secondPostValue = {
		track_user: true,
		tracking: {
			country_name: 'Unknown',
			region_name: 'Unknown',
			city: 'Unknown',
			zip_code: 'Unknown',
			ip: 'Unknown',
			latitude: 'Unknown',
			longitude: 'Unknown',
			browser_name: 'Chrome',
			browser_version: '80.0.3987.106',
			os_name: 'MacOS',
		},
	};

	const myData = await axios
		.post('https://app.myclma.com/api/session', secondPostValue, {
			headers: {
				'content-Type': 'application/json',
				'X-CSRF-Token': authToken,
			},
		})
		.catch(function(error) {
			console.log(error);
		});

	const { user: userTemp, account: acctTemp, success } = data;
	const orgOverrides = await getOrgOverrides(acctTemp.id);
	const userOverrides = await getUserPreferences(userTemp.id);
	const user = { ...userTemp, cwdaOverrides: userOverrides };
	const account = { ...acctTemp, cwdaOverrides: orgOverrides };

	setUserData(user);
	setOrgData(account);

	return { user, account, authToken, success };
};

export const changePassword = async (values, id) => {
	const putValue = {
		user: {
			oldPassword: values.oldPassword,
			password: values.password,
			confirmPassword: values.confirmPassword,
		},
	};

	const data = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/updatePassword/${id}`,
			putValue,
			{
				headers: {
					'content-Type': 'application/json',
				},
			}
		)
		.catch(function(error) {
			console.log(error);
		});
	return data;
};

export const createUser = async values => {
	const { data } = values;

	const postValue = {
		user2: {
			emailsMatch: true,
			country: 'US',
			country2: 'US',
			account_id: data.account_id,
			salutation: '',
			first_name: data.first_name,
			last_name: data.last_name,
			credentials: data.credentials,
			job_title: data.job_title,
			emailUnique: true,
			email: data.email,
			confirm_email: data.confirm_email,
			work_phone: data.work_phone,
			crafts: '79,80,13,7',
			sectors: '2,1,3,4',
			descr: '',
			address_type: 'P',
			address: data.address,
			city: data.city,
			state: data.state,
			zip_code: data.zip_code,
			confidentiality: true,
			antiTrust: true,
			temp_password: 'ov!y@4rm',
			password_confirmation: 'ov!y@4rm',
			password: 'ov!y@4rm',
		},
	};

	const userData = await axios
		.post(
			'https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/createUser',
			postValue,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	return userData;
};

export const createAccount = async values => {
	const postValue = {
		merit_shop: values.data.merit_shop,
		union: values.data.union,
		n_a: values.data.n_a,
		name: values.data.name,
		website_url: values.data.website_url,
		industry_role_id: values.industry_role_id,
		open_shop: values.data.open_shop,
		sectors: '2,3,4',
		crafts: '5,25,65',
		description: values.data.description,
		company_type: '3',
	};

	const data = await axios
		.post(
			'https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/createAccount',
			postValue,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	return data;
};

export const getAccounts = async () => {
	const data = await axios
		.get(`https://app.myclma.com/api/account`, {
			headers: { 'content-type': 'application/json' },
		})
		.catch(function(error) {
			console.log(error);
		});

	return data;
};

export const getUsers = async (token = 'b0bbf92d2e3ff6f00d346c296a76227e') => {
	const { data } = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getUsers`,
			{
				headers: {
					'content-type': 'application/json',
					'X-CSRF-Token': token,
				},
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	const userPromises = data.map(record =>
		docClient
			.get({
				TableName: userPrefsTable,
				Key: {
					id: record.id.toString(),
				},
			})
			.promise()
	);

	const userRowsRaw = await Promise.all(userPromises);

	const userRows = userRowsRaw.map(row => row.Item).filter(Boolean);

	userRows.forEach(row => {
		let clmaRowIndex = data.findIndex(({ id }) => id === parseInt(row.id));
		data[clmaRowIndex].cwdaOverrides = row;
	});

	return data;
};

export const getOwners = async (token = 'b0bbf92d2e3ff6f00d346c296a76227e') => {
	const filteredOwners = [];
	const { data } = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getUsers`,
			{
				headers: {
					'content-type': 'application/json',
					'X-CSRF-Token': token,
				},
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	return data;
};

export const getIndividualAccount = async id => {
	const data = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getAccountDetails/${id}`,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	return data;
};

export const getUsersForAccount = async id => {
	const data = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getUsersForAccount/${id}`,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	return data;
};

export const getOrgOverrides = async orgId => {
	const { Item = {} } = await docClient
		.get({
			TableName: orgPreferencesTable,
			Key: {
				id: orgId.toString(),
			},
		})
		.promise();
	return Item;
};

export const getAllAccounts = async () => {
	const industryRoles = [2, 3, 4, 5, 11, 12, 19, 6, 18];

	const { data } = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getAllAccounts`,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});

	const orgPromises = data.map(record =>
		docClient
			.get({
				TableName: orgPreferencesTable,
				Key: {
					id: record.id.toString(),
				},
			})
			.promise()
	);

	const orgRowsRaw = await Promise.all(orgPromises);

	const orgRows = orgRowsRaw.map(row => row.Item).filter(Boolean);

	orgRows.forEach(row => {
		let clmaRowIndex = data.findIndex(({ id }) => id === parseInt(row.id));
		data[clmaRowIndex].cwdaOverrides = row;
	});

	const result = data.filter(item =>
		industryRoles.includes(item.industry_role_id)
	);

	return result;
};

export const getProjectData = async id => {
	const data = await axios
		.post(
			`https://ws8virzfri.execute-api.us-east-1.amazonaws.com/dev/getProjectData/${id}`,
			{
				headers: { 'content-type': 'application/json' },
			}
		)
		.catch(function(error) {
			console.log(error);
		});
	return data;
};
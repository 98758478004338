import React from "react";
import {
  FlexibleXYPlot,
  HorizontalBarSeries,
  Crosshair,
  Hint,
  GradientDefs,
  MarkSeries,
} from "react-vis";
import "react-vis/dist/style.css";

const ComparisonBar = ({
  industryAverageScore,
  peerAverageScore,
  lriScore,
  you,
}) => {
  industryAverageScore = industryAverageScore ? industryAverageScore : 0;
  const industryAverageHintObject = { x: industryAverageScore, y: 0 };
  peerAverageScore = peerAverageScore ? peerAverageScore : 0;
  const peerAverageHintObject = { x: peerAverageScore, y: 0 };
  lriScore = lriScore ? lriScore : 0;
  const lriHintObject = { x: lriScore, y: 0 };
  const domainSettingValue =
    1.33 * Math.max(industryAverageScore, peerAverageScore, lriScore);
  const domainSettingHintObject = {
    x: domainSettingValue,
    y: 0,
    domainSettingHintObject: true,
  };
  const gradientPointYellow = (100 * 0.75) / domainSettingValue;
  const gradientPointGreenStarts = (100 * 1) / domainSettingValue;
  const data = [
    industryAverageHintObject,
    peerAverageHintObject,
    lriHintObject,
    domainSettingHintObject,
  ];
  const dataForCrosshair1 = [industryAverageHintObject, peerAverageHintObject];
  const dataForCrosshair2 = [peerAverageHintObject, lriHintObject];
  const dataForCrosshair3 = [lriHintObject, peerAverageHintObject];

  return (
    <section className="flex mx-auto w-3/4 h-32 mt-8">
      <FlexibleXYPlot margin={{ left: 0, right: 0, top: 16, bottom: 16 }}>
        <GradientDefs>
          <linearGradient id="CoolGradient" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stopColor="firebrick" />
            <stop offset={gradientPointYellow + "%"} stopColor="gold" />
            <stop
              offset={gradientPointGreenStarts + "%"}
              stopColor="yellowgreen"
            />
            <stop offset="100%" stopColor="forestgreen" />
          </linearGradient>
        </GradientDefs>

        <HorizontalBarSeries
          data={data}
          barWidth={0.25}
          color={"url(#CoolGradient)"}
          style={{ rx: "12", ry: "12" }}
        />
        <MarkSeries
          className=""
          color="white"
          stroke="white"
          size="4"
          colorType="literal"
          data={data.filter((d) => {
            return !d.domainSettingHintObject;
          })}
        />
        <Crosshair
          values={dataForCrosshair1}
          style={{ line: { background: "white", height: "1.75rem" } }}
        >
          <div style={{ background: "black" }}></div>
        </Crosshair>
        <Crosshair
          values={dataForCrosshair2}
          style={{ line: { background: "white", height: "3.75rem" } }}
        >
          <div style={{ background: "black" }}></div>
        </Crosshair>
        <Crosshair
          values={dataForCrosshair3}
          style={{
            line: {
              background: "white",
              height: "1.5rem",
              marginTop: "-1.5rem",
            },
          }}
        >
          <div style={{ background: "black" }}></div>
        </Crosshair>
        <Hint
          className="mt-4 whitespace-no-wrap"
          value={industryAverageHintObject}
          align={{
            vertical: "bottom",
          }}
        >
          <div className="px-1 rounded text-center bg-white text-black">
            <h3>
              Industry ({parseFloat(industryAverageHintObject.x).toFixed(2)})
            </h3>
          </div>
        </Hint>
        <Hint
          className="whitespace-no-wrap"
          style={{ marginTop: "2.75rem" }}
          value={peerAverageHintObject}
          align={{
            vertical: "bottom",
          }}
        >
          <div className="px-1 rounded text-center bg-white text-black">
            <h3>Peer ({parseFloat(peerAverageHintObject.x).toFixed(2)})</h3>
          </div>
        </Hint>
        <Hint
          className="mb-4 whitespace-no-wrap"
          value={lriHintObject}
          align={{ vertical: "top" }}
        >
          <div className="px-1 rounded text-center bg-white text-black">
            <h3>My LRI ({parseFloat(lriHintObject.x).toFixed(2)})</h3>
          </div>
        </Hint>
      </FlexibleXYPlot>
    </section>
  );
};

export default ComparisonBar;

import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import IndustryComparison from "./IndustryComparison/IndustryComparison";
import Simulator from "./Simulator/Simulator";
import Overview from "./Hero/Overview";
import Chart from "./Chart/Chart";
// import Hashids from "hashids";
import { getProjectData } from "../../contexts/authHelpers";
import { getLRIData, getLRIAverages } from "../../daos/lriData";
import LaborShortageRisk from "../LRIAdmin/LaborShortageRisk";
import SafetyPerformance from "../LRIAdmin/SafetyPerformance";
import LaborProductivity from "../LRIAdmin/LaborProductivity";
import QualityPerformance from "../LRIAdmin/QualityPerformance";

import isEmpty from "lodash.isempty";

// const hashids = new Hashids("lri");

const LRIOverview = () => {
  const { id } = useParams();

  // const [decodedProjectId, setDecodedProjectId] = useState(hashids.decode(id));
  // const [encodedProjectId, setEncodedProjectId] = useState(hashids.encode(id));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // scores
  const [lriScore, setLriScore] = useState("");
  const [originalLriScore, setOriginalLriScore] = useState("");

  // overview data
  const [projectName, setProjectName] = useState("");
  const [projectOwner, setProjectOwner] = useState("");
  const [projectContractor, setProjectContractor] = useState("");
  const [totalIncludedCost, setTotalIncludedCost] = useState("");
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState("");
  const [industryRole, setIndustryRole] = useState("");

  // chart data
  const [safetyPerformanceScore, setSafetyPerformanceScore] = useState("");
  const [laborShortageRiskScore, setLaborShortageRiskScore] = useState("");
  const [laborProductivityScore, setLaborProductivityScore] = useState("");
  const [laborEfficiencyScore, setLaborEfficiencyScore] = useState("");
  const [qualityPerformanceScore, setQualityPerformanceScore] = useState("");
  const [cwdaScore, setCwdaScore] = useState("");
  const [scaledCwdaScore, setScaledCwdaScore] = useState("");
  const [industryLriAvg, setIndustryLriAvg] = useState("");
  const [peerLriAvg, setPeerLriAvg] = useState("");

  // actual and simulated data
  const [simulatorToggle, setSimulatorToggle] = useState(false);
  const [selectedShortage, setSelectedShortage] = useState("");
  const [actualShortageBucket, setActualShortageBucket] = useState("");
  const [
    simulatedNoShortageCostReduction,
    setSimulatedNoShortageCostReduction,
  ] = useState("");
  const [
    simulatedNoShortageDurationReduction,
    setSimulatedNoShortageDurationReduction,
  ] = useState("");
  const [
    simulatedModerateShortageCostReduction,
    setSimulatedModerateShortageCostReduction,
  ] = useState("");
  const [
    simulatedModerateShortageDurationReduction,
    setSimulatedModerateShortageDurationReduction,
  ] = useState("");
  const [
    simulatedModerateShortageLRI,
    setSimulatedModerateShortageLRI,
  ] = useState("");
  const [
    simulatedSevereShortageCostReduction,
    setSimulatedSevereShortageCostReduction,
  ] = useState("");
  const [
    simulatedSevereShortageDurationReduction,
    setSimulatedSevereShortageDurationReduction,
  ] = useState("");
  const [simulatedSevereShortageLRI, setSimulatedSevereShortageLRI] = useState(
    ""
  );
  const [simulatedNoShortageLRI, setSimulatedNoShortageLRI] = useState("");
  const [
    simulatedNoShortageSafetyPerformanceScore,
    setSimulatedNoShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedNoShortageLaborShortageRiskScore,
    setSimulatedNoShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedNoShortageLaborProductivityScore,
    setSimulatedNoShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedNoShortageQualityPerformanceScore,
    setSimulatedNoShortageQualityPerformanceScore,
  ] = useState("");

  // const [
  //   simulatedModerateShortageCostReduction,
  //   setSimulatedModerateShortageCostReduction,
  // ] = useState("");
  // const [
  //   simulatedModerateShortageDurationReduction,
  //   setSimulatedModerateShortageDurationReduction,
  // ] = useState("");
  // const [
  //   simulatedModerateShortageLRI,
  //   setSimulatedModerateShortageLRI,
  // ] = useState("");
  const [
    simulatedModerateShortageSafetyPerformanceScore,
    setSimulatedModerateShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedModerateShortageLaborShortageRiskScore,
    setSimulatedModerateShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedModerateShortageLaborProductivityScore,
    setSimulatedModerateShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedModerateShortageQualityPerformanceScore,
    setSimulatedModerateShortageQualityPerformanceScore,
  ] = useState("");
  // const [
  //   simulatedSevereShortageCostReduction,
  //   setSimulatedSevereShortageCostReduction,
  // ] = useState("");
  // const [
  //   simulatedSevereShortageDurationReduction,
  //   setSimulatedSevereShortageDurationReduction,
  // ] = useState("");
  // const [simulatedSevereShortageLRI, setSimulatedSevereShortageLRI] = useState(
  //   ""
  // );
  const [
    simulatedSevereShortageSafetyPerformanceScore,
    setSimulatedSevereShortageSafetyPerformanceScore,
  ] = useState("");
  const [
    simulatedSevereShortageLaborShortageRiskScore,
    setSimulatedSevereShortageLaborShortageRiskScore,
  ] = useState("");
  const [
    simulatedSevereShortageLaborProductivityScore,
    setSimulatedSevereShortageLaborProductivityScore,
  ] = useState("");
  const [
    simulatedSevereShortageQualityPerformanceScore,
    setSimulatedSevereShortageQualityPerformanceScore,
  ] = useState("");

  // table data

  const [safetyPerformanceData, setSafetyPerformanceData] = useState([]);
  const [laborShortageRiskData, setLaborShortageRiskData] = useState([]);
  const [laborProductivityData, setLaborProductivityData] = useState([]);
  const [qualityPerformanceData, setQualityPerformanceData] = useState([]);

  // data completeness
  const [dataCompletenessCurrent, setDataCompletenessCurrent] = useState(0);
  const [dataCompletenessTotal, setDataCompletenessTotal] = useState(0);

  const getInitialData = useCallback(async () => {
    setIsLoading(true);
    const projectRecord = await getProjectData(id);
    if (!isEmpty(projectRecord)) {
      setProjectName(projectRecord.data.name);
      setTotalIncludedCost(projectRecord.data.total_cost);
      const startDate = projectRecord.data.start_date;
      const startDateDate = new Date(
        startDate.slice(0, 4),
        startDate.slice(5, 7) - 1,
        startDate.slice(8, 10)
      );
      const startDateMonthName = startDateDate.toLocaleString("default", {
        month: "short",
      });
      const endDate = projectRecord.data.end_date;
      const endDateDate = new Date(
        endDate.slice(0, 4),
        endDate.slice(5, 7) - 1,
        endDate.slice(8, 10)
      );
      const endDateMonthName = endDateDate.toLocaleString("default", {
        month: "short",
      });
      setDuration(
        startDateDate.getFullYear() +
          startDateMonthName +
          startDateDate.getDate() +
          " - " +
          endDateDate.getFullYear() +
          endDateMonthName +
          endDateDate.getDate()
      );
      setLocation(
        projectRecord.data.city +
          ", " +
          projectRecord.data.state_abbr +
          " " +
          projectRecord.data.zip
      );
    }

    const lriRecord = await getLRIData({ projectId: id });
    if (!isEmpty(lriRecord)) {
      setProjectOwner(lriRecord.projectOwner);
      setProjectContractor(lriRecord.projectContractor);
      setIndustryRole(lriRecord.industryRole);
      setLriScore(
        Math.round((parseFloat(lriRecord.lriScore) + Number.EPSILON) * 100) /
          100
      );
      setOriginalLriScore(
        Math.round((parseFloat(lriRecord.lriScore) + Number.EPSILON) * 100) /
          100
      );
      const lriAverages = await getLRIAverages({
        industryRole: lriRecord.industryRole,
      });
      setIndustryLriAvg(lriAverages?.lriIndustryScoresAvg);
      setPeerLriAvg(lriAverages?.lriPeerScoresAvg);
      setSafetyPerformanceScore(lriRecord.safetyPerformanceScore);
      setLaborShortageRiskScore(lriRecord.laborShortageRiskScore);
      setLaborProductivityScore(lriRecord.laborProductivityScore);
      setLaborEfficiencyScore(lriRecord.laborEfficiencyScore);
      setQualityPerformanceScore(lriRecord.qualityPerformanceScore);
      setCwdaScore(lriRecord.cwdaScore);
      setScaledCwdaScore(lriRecord.scaledCwdaScore);
      setActualShortageBucket(lriRecord.actualShortageBucket);
      setSelectedShortage(lriRecord.actualShortageBucket);
      setSimulatedNoShortageCostReduction(
        lriRecord.simulatedNoShortageCostReduction
      );
      setSimulatedNoShortageDurationReduction(
        lriRecord.simulatedNoShortageDurationReduction
      );
      setSimulatedNoShortageLRI(lriRecord.simulatedNoShortageLRI);
      setSimulatedNoShortageSafetyPerformanceScore(
        lriRecord.simulatedNoShortageSafetyPerformanceScore
      );
      setSimulatedNoShortageLaborShortageRiskScore(
        lriRecord.simulatedNoShortageLaborShortageRiskScore
      );
      setSimulatedNoShortageLaborProductivityScore(
        lriRecord.simulatedNoShortageLaborProductivityScore
      );
      setSimulatedNoShortageQualityPerformanceScore(
        lriRecord.simulatedNoShortageQualityPerformanceScore
      );
      setSimulatedModerateShortageCostReduction(
        lriRecord.simulatedModerateShortageCostReduction
      );
      setSimulatedModerateShortageDurationReduction(
        lriRecord.simulatedModerateShortageDurationReduction
      );
      setSimulatedModerateShortageLRI(lriRecord.simulatedModerateShortageLRI);
      setSimulatedModerateShortageSafetyPerformanceScore(
        lriRecord.simulatedModerateShortageSafetyPerformanceScore
      );
      setSimulatedModerateShortageLaborShortageRiskScore(
        lriRecord.simulatedModerateShortageLaborShortageRiskScore
      );
      setSimulatedModerateShortageLaborProductivityScore(
        lriRecord.simulatedModerateShortageLaborProductivityScore
      );
      setSimulatedModerateShortageQualityPerformanceScore(
        lriRecord.simulatedModerateShortageQualityPerformanceScore
      );
      setSimulatedSevereShortageCostReduction(
        lriRecord.simulatedSevereShortageCostReduction
      );
      setSimulatedSevereShortageDurationReduction(
        lriRecord.simulatedSevereShortageDurationReduction
      );
      setSimulatedSevereShortageLRI(lriRecord.simulatedSevereShortageLRI);
      setSimulatedSevereShortageSafetyPerformanceScore(
        lriRecord.simulatedSevereShortageSafetyPerformanceScore
      );
      setSimulatedSevereShortageLaborShortageRiskScore(
        lriRecord.simulatedSevereShortageLaborShortageRiskScore
      );
      setSimulatedSevereShortageLaborProductivityScore(
        lriRecord.simulatedSevereShortageLaborProductivityScore
      );
      setSimulatedSevereShortageQualityPerformanceScore(
        lriRecord.simulatedSevereShortageQualityPerformanceScore
      );

      // table data
      setSafetyPerformanceData(lriRecord?.lriData?.safetyPerformanceData);
      setLaborShortageRiskData(lriRecord?.lriData?.laborShortageRiskData);
      setLaborProductivityData(lriRecord?.lriData?.laborProductivityData);
      setQualityPerformanceData(lriRecord?.lriData?.qualityPerformanceData);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (simulatorToggle) {
      switch (selectedShortage) {
        case "Shortage (None to Minimal)":
          setLriScore(simulatedNoShortageLRI);
          break;
        case "Shortage (Minimal to Moderate)":
          setLriScore(simulatedModerateShortageLRI);
          break;
        case "Shortage (Moderate to Severe)":
          setLriScore(simulatedSevereShortageLRI);
          break;
        default:
          setLriScore(originalLriScore);
          break;
      }
    } else {
      setLriScore(originalLriScore);
    }
  }, [
    originalLriScore,
    selectedShortage,
    simulatedModerateShortageLRI,
    simulatedNoShortageLRI,
    simulatedSevereShortageLRI,
    simulatorToggle,
  ]);

  useEffect(() => {
    getInitialData();
  }, []);

  const calculateDataCompleteness = useCallback(() => {
    // reset data completeness so we can re-calculate it
    let localDataCompletenessCurrent = 0;
    let localDataCompletenessTotal = 0;

    localDataCompletenessTotal += 3;
    if (safetyPerformanceData) {
      if (safetyPerformanceData[0]) {
        if (safetyPerformanceData[0].value) {
          localDataCompletenessCurrent++;
        }
      }
      if (safetyPerformanceData[1]) {
        if (safetyPerformanceData[1].value) {
          localDataCompletenessCurrent++;
        }
      }
      if (safetyPerformanceData[2]) {
        if (safetyPerformanceData[2].value) {
          localDataCompletenessCurrent++;
        }
      }
    }

    localDataCompletenessTotal += 6;
    if (laborShortageRiskData) {
      laborShortageRiskData.forEach((element, index) => {
        if (index !== 0) localDataCompletenessTotal += 5;
        if (index === 0 && element?.LaborShortageRiskCraft)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalIndustrySupply)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalPeakDemand)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskContractorWageRate)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskRegionalWageRate)
          localDataCompletenessCurrent++;
        if (element?.LaborShortageRiskAnnualNetAttrition)
          localDataCompletenessCurrent++;
      });
    }

    localDataCompletenessTotal += 3;
    if (laborProductivityData) {
      laborProductivityData.forEach((element, index) => {
        if (index !== 0) localDataCompletenessTotal += 2;
        if (index === 0 && element?.LaborProductivityCraft)
          localDataCompletenessCurrent++;
        if (element?.LaborProductivityContractorProductivity)
          localDataCompletenessCurrent++;
        if (element?.LaborProductivityIndustryAverageProductivity)
          localDataCompletenessCurrent++;
      });
    }

    localDataCompletenessTotal += 1;
    if (laborEfficiencyScore) localDataCompletenessCurrent++;

    localDataCompletenessTotal += 2;
    if (qualityPerformanceData) {
      if (qualityPerformanceData[0]?.QualityPerformanceContractorQPR)
        localDataCompletenessCurrent++;
      if (qualityPerformanceData[0]?.QualityPerformanceIndustryAverageQPR)
        localDataCompletenessCurrent++;
    }

    localDataCompletenessTotal += 1;
    if (cwdaScore) localDataCompletenessCurrent++;

    setDataCompletenessCurrent(localDataCompletenessCurrent);
    setDataCompletenessTotal(localDataCompletenessTotal);
  }, [
    cwdaScore,
    laborEfficiencyScore,
    laborProductivityData,
    laborShortageRiskData,
    qualityPerformanceData,
    safetyPerformanceData,
  ]);

  useEffect(() => {
    if (!isLoading) {
      calculateDataCompleteness();
    }
  }, [calculateDataCompleteness, isLoading]);

  return (
    <div className="flex flex-col mx-auto max-w-4xl">
      <div className="flex flex-col mx-auto my-4 shadow rounded">
        <Overview
          projectName={projectName}
          projectOwner={projectOwner}
          projectContractor={projectContractor}
          totalIncludedCost={totalIncludedCost}
          duration={duration}
          location={location}
        />
        <Simulator
          simulatorToggle={simulatorToggle}
          setSimulatorToggle={setSimulatorToggle}
          selectedShortage={selectedShortage}
          setSelectedShortage={setSelectedShortage}
          actualShortageBucket={actualShortageBucket}
          simulatedNoShortageCostReduction={simulatedNoShortageCostReduction}
          setSimulatedNoShortageCostReduction={
            setSimulatedNoShortageCostReduction
          }
          simulatedNoShortageDurationReduction={
            simulatedNoShortageDurationReduction
          }
          setSimulatedNoShortageDurationReduction={
            setSimulatedNoShortageDurationReduction
          }
          simulatedModerateShortageCostReduction={
            simulatedModerateShortageCostReduction
          }
          setSimulatedModerateShortageCostReduction={
            simulatedModerateShortageCostReduction
          }
          simulatedModerateShortageDurationReduction={
            simulatedModerateShortageDurationReduction
          }
          setSimulatedModerateShortageDurationReduction={
            setSimulatedModerateShortageDurationReduction
          }
          simulatedSevereShortageCostReduction={
            simulatedSevereShortageCostReduction
          }
          setSimulatedSevereShortageCostReduction={
            setSimulatedSevereShortageCostReduction
          }
          simulatedSevereShortageDurationReduction={
            simulatedSevereShortageDurationReduction
          }
          setSimulatedSevereShortageDurationReduction={
            setSimulatedSevereShortageDurationReduction
          }
        />
        <IndustryComparison
          lriScore={lriScore}
          you={projectContractor}
          industryLriAvg={industryLriAvg}
          peerLriAvg={peerLriAvg}
          dataCompletenessCurrent={dataCompletenessCurrent}
          dataCompletenessTotal={dataCompletenessTotal}
        />
        <Chart
          safetyPerformanceScore={safetyPerformanceScore}
          laborShortageRiskScore={laborShortageRiskScore}
          laborProductivityScore={laborProductivityScore}
          qualityPerformanceScore={qualityPerformanceScore}
          cwdaScore={scaledCwdaScore}
          simulatorToggle={simulatorToggle}
          selectedShortage={selectedShortage}
          simulatedNoShortageSafetyPerformanceScore={
            simulatedNoShortageSafetyPerformanceScore
          }
          simulatedNoShortageLaborShortageRiskScore={
            simulatedNoShortageLaborShortageRiskScore
          }
          simulatedNoShortageLaborProductivityScore={
            simulatedNoShortageLaborProductivityScore
          }
          simulatedNoShortageQualityPerformanceScore={
            simulatedNoShortageQualityPerformanceScore
          }
          simulatedModerateShortageSafetyPerformanceScore={
            simulatedModerateShortageSafetyPerformanceScore
          }
          simulatedModerateShortageLaborShortageRiskScore={
            simulatedModerateShortageLaborShortageRiskScore
          }
          simulatedModerateShortageLaborProductivityScore={
            simulatedModerateShortageLaborProductivityScore
          }
          simulatedModerateShortageQualityPerformanceScore={
            simulatedModerateShortageQualityPerformanceScore
          }
          simulatedSevereShortageSafetyPerformanceScore={
            simulatedSevereShortageSafetyPerformanceScore
          }
          simulatedSevereShortageLaborShortageRiskScore={
            simulatedSevereShortageLaborShortageRiskScore
          }
          simulatedSevereShortageLaborProductivityScore={
            simulatedSevereShortageLaborProductivityScore
          }
          simulatedSevereShortageQualityPerformanceScore={
            simulatedSevereShortageQualityPerformanceScore
          }
        />
      </div>
      <div>
        <div className="w-full md:max-w-4xl mx-auto mt-8">
          <div className="py-4">
            <SafetyPerformance
              projectId={id}
              safetyPerformanceData={safetyPerformanceData}
              setSafetyPerformanceData={setSafetyPerformanceData}
              safetyPerformanceScore={safetyPerformanceScore}
              admin={false}
            />
          </div>
          <div className="py-4">
            <LaborShortageRisk
              projectId={id}
              laborShortageRiskData={laborShortageRiskData}
              setLaborShortageRiskData={setLaborShortageRiskData}
              laborShortageRiskScore={laborShortageRiskScore}
              laborShortageBucket={actualShortageBucket}
              admin={false}
            />
          </div>
          <div className="py-4">
            <LaborProductivity
              projectId={id}
              laborProductivityData={laborProductivityData}
              setLaborProductivityData={setLaborProductivityData}
              laborProductivityScore={laborProductivityScore}
              laborEfficiencyScore={laborEfficiencyScore}
              admin={false}
            />
          </div>
          <div className="py-4">
            <QualityPerformance
              projectId={id}
              qualityPerformanceData={qualityPerformanceData}
              setQualityPerformanceData={setQualityPerformanceData}
              qualityPerformanceScore={qualityPerformanceScore}
              admin={false}
            />
          </div>
          <div>
            <div className="flex flex-col max-w-full mb-4">
              <div className="flex flex-row items-center h-16">
                <div className="flex my-auto rounded-full h-3 w-3 bg-blue-500"></div>
                <div className="ml-2 text-gray-800">
                  Contractors Workforce Development Assessment
                </div>
              </div>
              <div className="flex flex-row divide-x divide-gray-500">
                <div
                  className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded"
                  style={{
                    backgroundImage:
                      "linear-gradient(to top left, dimgray, black)",
                  }}
                >
                  <div className="">CWDA Score:</div>
                  <div className="font-bold">{Math.round(cwdaScore)}%</div>
                </div>
                <div
                  className="flex flex-row w-full justify-between h-16 px-4 items-center text-white rounded"
                  style={{
                    backgroundImage:
                      "linear-gradient(to top left, dimgray, black)",
                  }}
                >
                  <div className="">Scaled CWDA Score:</div>
                  <div className="font-bold">
                    {new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(scaledCwdaScore)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LRIOverview;

import React from 'react';

const Input = ({ placeholder, value, onChange, ...rest }) => {
	return (
		<div className="relative flex w-full flex-wrap items-stretch mb-3">
			<input
				{...rest}
				type="text"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				className="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:shadow-outline w-full pr-10 text-center"
			/>
			<span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
				<i className="fas fa-question-circle"></i>
			</span>
		</div>
	);
};

export default Input;

import AWS from 'aws-sdk';
import { v4 as uuid } from 'uuid';
import { getIndividualAccount } from '../contexts/authHelpers';

const config = require('../config/dev.json');

const userPrefsTable = 'cwda-user-preferences';
const shareScoreCardTable = 'cwda-shared-scorecards';

AWS.config.update({
	region: 'us-east-1',
	accessKeyId: config.aws.a,
	secretAccessKey: config.aws.s,
});

const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-08-10' });
const defaultUserPreference = {
	isActive: true,
	avatar:
		'https://static.grainger.com/rp/s/is/image/Grainger/4LN70_AS01?$zmmain$',
};

export const getUserPreferences = async id => {
	if (!id) return {};

	const data = await docClient
		.get({
			TableName: userPrefsTable,
			Key: {
				id: id.toString(),
			},
		})
		.promise();

	const { Item = {} } = data;

	return { ...defaultUserPreference, ...Item };
};

export const updateUsersAvatar = async ({ id, avatar }) => {
	if (!id || !avatar) return {};

	await docClient
		.update({
			TableName: userPrefsTable,
			Key: {
				id,
			},
			UpdateExpression: 'set avatar = :avatar',
			ExpressionAttributeValues: {
				':avatar': avatar,
			},
			ReturnValues: 'ALL_NEW',
		})
		.promise();

	return {};
};

export const updateUsersActiveFlag = async (id, isActive) => {
	if (!id) return {};

	const myId = id.toString();

	const data = await docClient
		.update({
			TableName: userPrefsTable,
			Key: {
				id: myId,
			},
			UpdateExpression: 'set isActive = :isActive',
			ExpressionAttributeValues: {
				':isActive': isActive,
			},
			ReturnValues: 'ALL_NEW',
		})
		.promise();

	return {};
};

export const updatePrimary = async (id, isPrimary) => {
	if (!id) return {};

	const myId = id.toString();

	const data = await docClient
		.update({
			TableName: userPrefsTable,
			Key: {
				id: myId,
			},
			UpdateExpression: 'set isPrimary = :isPrimary',
			ExpressionAttributeValues: {
				':isPrimary': isPrimary,
			},
			ReturnValues: 'ALL_NEW',
		})
		.promise();

	return {};
};

export const updateAllAccess = async contacts => {
	const updates = contacts.map(contact =>
		docClient
			.update({
				TableName: shareScoreCardTable,
				Key: {
					id: contact.id,
				},
				UpdateExpression:
					'set disabled = :disabled, includeImprovementReport = :includeImprovementReport ',
				ExpressionAttributeValues: {
					':disabled': !!contact.disabled,
					':includeImprovementReport': !!contact.includeImprovementReport,
				},
				ReturnValues: 'ALL_NEW',
			})
			.promise()
	);

	const data = await Promise.all(updates);
	console.log(data);
	return data;
};

export const shareMyScoreCard = async data => {
	if (!data) return {};

	const assessmentType = data.assessmentType;
	const organization = data.organizationId;
	const assessmentYear = data.year;
	const sharedBy = data.email;
	const sharedByUserId = data.id;
	const sharedWith = data.recipient.email;
	const sharedWithOrganization = data.recipient.value;
	const sharedWithCompanyName = data.recipient.photo;

	const response = await docClient
		.update({
			TableName: shareScoreCardTable,
			Key: {
				id: uuid(),
			},
			UpdateExpression:
				'set assessmentType = :assessmentType, organization = :organization, assessmentYear = :assessmentYear, sharedBy = :sharedBy, sharedByUserId = :sharedByUserId, sharedWith = :sharedWith, sharedWithOrganization = :sharedWithOrganization, sharedWithCompanyName = :sharedWithCompanyName',
			ExpressionAttributeValues: {
				':assessmentType': assessmentType,
				':organization': organization,
				':assessmentYear': assessmentYear,
				':sharedBy': sharedBy,
				':sharedByUserId': sharedByUserId,
				':sharedWith': sharedWith,
				':sharedWithOrganization': sharedWithOrganization,
				':sharedWithCompanyName': sharedWithCompanyName,
			},
			ReturnValues: 'ALL_NEW',
		})
		.promise();

	return response;
};

export const getShareScoreCard = async email => {
	if (!email) return {};

	const sharedWith = email;

	const data = await docClient
		.scan({
			TableName: shareScoreCardTable,
			FilterExpression: 'sharedWith = :sharedWith',
			ExpressionAttributeValues: {
				':sharedWith': sharedWith,
			},
		})
		.promise();

	const scoreCardPromises = data.Items.map(record =>
		getIndividualAccount(parseInt(record.organization))
	);

	const scoreCardRows = await Promise.all(scoreCardPromises);

	scoreCardRows.forEach(row => {
		data.Items.map(item => {
			if (row.data.id === parseInt(item.organization)) {
				item.organizationName = row.data.name;
			}
		});
	});

	const { Item = {} } = data;

	return data.Items;
};

export const getScoreCardsIShared = async email => {
	if (!email) return {};

	const sharedBy = email;

	const data = await docClient
		.scan({
			TableName: shareScoreCardTable,
			FilterExpression: 'sharedBy = :sharedBy',
			ExpressionAttributeValues: {
				':sharedBy': sharedBy,
			},
		})
		.promise();

	const scoreCardPromises = data.Items.map(record =>
		getIndividualAccount(parseInt(record.organization))
	);

	const scoreCardRows = await Promise.all(scoreCardPromises);

	scoreCardRows.forEach(row => {
		data.Items.map(item => {
			if (row.data.id === parseInt(item.organization)) {
				item.organizationName = row.data.name;
			}
		});
	});

	const { Item = {} } = data;

	return data.Items;
};

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import LRIOverview from "./scenes/LRIOverview";
import LRIAdmin from "./scenes/LRIAdmin";
import LRIProject from "./scenes/LRIProject";

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

export default function AuthExample() {

  return (
    <Router>
      <div>
        {/* <AuthButton /> */}

        {/* <div className="flex flex-row p-4 shadow-md">
          <ul>
            {id ? (
              <li className="bg-blue-500 p-2 mr-4">
                <Link to={"/project/" + id}>Overview</Link>
              </li>
            ) : null}
            {id ? (
              <li className="bg-blue-500 p-2">
                <Link to={"/project/" + id + "/details"}>Details</Link>
              </li>
            ) : null}
            
            <li>
              <button
                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                <Link to="/project">Load Project</Link>
              </button>
            </li>
          </ul>
          <div className="flex self-center pl-4">
            Changes are automatically saved when leaving any field.
          </div>
        </div> */}

        <div className="p-4">
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <PrivateRoute path="/project/:id/details">
              <LRIAdmin />
            </PrivateRoute>
            <PrivateRoute path="/project/:id">
              <LRIOverview />
            </PrivateRoute>
            <PrivateRoute path="/">
              <LRIProject />
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    if (
      localStorage.getItem("lriRoles") &&
      localStorage.getItem("lriRoles").includes("admin")
    ) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    }
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function AuthButton() {
  let history = useHistory();

  return fakeAuth.isAuthenticated ? (
    <p>
      Welcome!{" "}
      <button
        onClick={() => {
          fakeAuth.signout(() => history.push("/"));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LoginPage() {
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };
  let login = () => {
    fakeAuth.authenticate(() => {
      history.replace(from);
    });
  };

  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <button
        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
        type="button"
        style={{ transition: "all .15s ease" }}
        onClick={login}
      >
        Login
      </button>
    </div>
  );
}

import React, {useState, useEffect} from 'react';

import './Toggle.css';

const Toggle = ({setToggle}) => {

	const [toggleValue, setToggleValue] = useState(false);

	const onChange = (e) => {
		setToggleValue(!toggleValue);
	}

	useEffect(() => {
		setToggle(toggleValue);
	}, [toggleValue, setToggle])


	return (
		<>
			<input className="tgl tgl-light" id="toggle" type="checkbox" value={toggleValue} onChange={onChange}/>
			<label className="tgl-btn m-auto" htmlFor="toggle"></label>
		</>
	);
};

export default Toggle;

import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Dropdown"
import Toggle from "./Toggle";

function Simulator({
  simulatorToggle,
  setSimulatorToggle,
  selectedShortage,
  setSelectedShortage,
  actualShortageBucket,
  simulatedNoShortageCostReduction,
  setSimulatedNoShortageCostReduction,
  simulatedNoShortageDurationReduction,

  setSimulatedNoShortageDurationReduction,

  simulatedModerateShortageCostReduction,

  setSimulatedModerateShortageCostReduction,

  simulatedModerateShortageDurationReduction,

  setSimulatedModerateShortageDurationReduction,
  simulatedSevereShortageCostReduction,
  setSimulatedSevereShortageCostReduction,
  simulatedSevereShortageDurationReduction,
  setSimulatedSevereShortageDurationReduction,
}) {
  const [showEstimatedCostReduction, setShowEstimatedCostReduction] = useState(
    false
  );
  const [estimatedCostReduction, setEstimatedCostReduction] = useState(0);
  const [estimatedDurationReduction, setEstimatedDurationReduction] = useState(
    0
  );

  useEffect(() => {
    if (simulatorToggle) {
      switch (selectedShortage) {
        case "Shortage (None to Minimal)":
          setEstimatedCostReduction(simulatedNoShortageCostReduction);
          setEstimatedDurationReduction(simulatedNoShortageDurationReduction);
          break;
        case "Shortage (Minimal to Moderate)":
          setEstimatedCostReduction(simulatedModerateShortageCostReduction);
          setEstimatedDurationReduction(
            simulatedModerateShortageDurationReduction
          );
          break;
        case "Shortage (Moderate to Severe)":
          setEstimatedCostReduction(simulatedSevereShortageCostReduction);
          setEstimatedDurationReduction(
            simulatedSevereShortageDurationReduction
          );
          break;
        default:
          break;
      }
    }
  }, [
    selectedShortage,
    showEstimatedCostReduction,
    simulatedModerateShortageCostReduction,
    simulatedModerateShortageDurationReduction,
    simulatedNoShortageCostReduction,
    simulatedNoShortageDurationReduction,
    simulatedSevereShortageCostReduction,
    simulatedSevereShortageDurationReduction,
    simulatorToggle,
  ]);

  return (
    <section className="grid grid-cols-2 gap-4 mx-20 -mt-10 mb-8">
      <section className="grid grid-cols-2 gap-4 col-span-2 bg-white rounded shadow h-20">
        <div className="flex">
          <div className="flex m-auto">
            <Toggle setToggle={setSimulatorToggle} />
          </div>
          <div className="flex m-auto text-left text-gray-600 text-sm leading-snug">
            Recalculate LRI if labor availability risk is...
          </div>
        </div>
        <div className="self-center pr-4">
          <Dropdown
            selected={selectedShortage}
            setSelected={setSelectedShortage}
            options={[
              "Shortage (None to Minimal)",
              "Shortage (Minimal to Moderate)",
              "Shortage (Moderate to Severe)",
            ]}
          />
        </div>
      </section>
      {simulatorToggle ? (
        <section className="grid grid-cols-2 gap-4 col-span-2">
          <div className="flex flex-row mx-auto">
            <div className="self-center text-5xl font-semibold pr-6">
              <span
                className={
                  estimatedCostReduction >= 0
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                {estimatedCostReduction}
              </span>
              <span className="text-2xl text-black relative align-text-top">
                %
              </span>
            </div>
            <div className="flex w-16 leading-snug my-auto">
              Potential Cost Improvement
            </div>
          </div>
          <div className="flex flex-row mx-auto">
            <div className="self-center text-green-500 text-5xl font-semibold pr-8">
              <span
                className={
                  estimatedDurationReduction >= 0
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                {estimatedDurationReduction}
              </span>
              <span className="text-2xl text-black relative align-text-top">
                %
              </span>
            </div>
            <div className="flex w-16 leading-snug my-auto">
              Potential Duration Improvement
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
}

export default Simulator;

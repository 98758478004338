import React from 'react';

const Button = ({ onClick, children }) => {
  // const style = {
  //   transition: 'all .15s ease'
  // }

	return (
		<>
			<button
				className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1"
				type="button"
        // style={style}
        onClick={onClick}
			>
				{children}
			</button>
		</>
	);
};

export default Button;

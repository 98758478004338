import React from "react";
import HeroStat from "./HeroStat";

const Overview = ({
  projectName,
  projectOwner,
  projectContractor,
  totalIncludedCost,
  duration,
  location,
}) => {
  return (
    <section
      className="flex flex-col p-4 rounded-t "
      style={{
        backgroundImage: "linear-gradient(to top left, dimgray, black)"
      }}
    >
      <header className="text-lg text-left text-white">Labor Risk Index</header>

      <div className="grid grid-cols-3 gap-4 mx-auto my-8 pb-4">
        <HeroStat data={projectName} label="Project Name" />
        <HeroStat data={projectOwner} label="Project Owner" />
        <HeroStat data={projectContractor} label="Project Contractor" />
        <HeroStat data={totalIncludedCost} label="TIC" isCurrency />
        <HeroStat data={duration} label="Duration" />
        <HeroStat data={location} label="Location" />
      </div>
    </section>
  );
};

export default Overview;
